import { EstimateCosmosTxParams, SendCosmosTokensParams } from '@/services/LedgerBridge';
import { wait } from '@/utils/wait';
import { rawSecp256k1PubkeyToRawAddress, StdSignDoc } from '@cosmjs/amino';
import { SigningCosmWasmClient } from '@cosmjs/cosmwasm-stargate';
import { LedgerSigner as CosmosLedgerSigner } from '@cosmjs/ledger-amino';
import { EncodeObject } from '@cosmjs/proto-signing';
import { coins, makeCosmoshubPath, SigningStargateClient } from '@cosmjs/stargate';
import AppEth, { ledgerService } from '@ledgerhq/hw-app-eth';
import AppIcx from '@ledgerhq/hw-app-icx';
import AppStr from '@ledgerhq/hw-app-str';
import AppSui from '@mysten/ledgerjs-hw-app-sui';
import AppBtc from '@ledgerhq/hw-app-btc';
import { Psbt, Transaction as BtcTransaction } from 'bitcoinjs-lib';
import { messageWithIntent, toSerializedSignature } from '@mysten/sui/cryptography';
import { Ed25519PublicKey } from '@mysten/sui/keypairs/ed25519';
import Transport from '@ledgerhq/hw-transport';
import TransportU2F from '@ledgerhq/hw-transport-u2f';
import TransportWebHID from '@ledgerhq/hw-transport-webhid';
import TransportWebUSB from '@ledgerhq/hw-transport-webusb';
import { encodeAddress } from '@polkadot/util-crypto';
import { CosmosApp } from '@zondax/ledger-cosmos-js';
import { newKusamaApp, newPolkadotApp, SubstrateApp } from '@zondax/ledger-substrate';
import { MsgExecuteContract } from 'cosmjs-types/cosmwasm/wasm/v1/tx';
import { isEmpty, isNil } from 'lodash-es';
import { ethers } from 'ethers';
import { PublicKey } from '@solana/web3.js';
import AppSol from '@ledgerhq/hw-app-solana';

export enum AppType {
  ETH = 'ETH',
  ICX = 'ICX',
  DOT = 'DOT',
  KSM = 'KSM',
  WND = 'WND',
  ATOM = 'ATOM',
  XLM = 'XLM',
  SUI = 'SUI',
  SOL = 'SOL',
  BTC = 'BTC',
}

export enum LedgerErrors {
  NotSupported = 'NotSupported',
  NotConnected = 'NotConnected',
  NoAddress = 'NoAddress',
  IncorrectDevice = 'IncorrectDevice',
}

export interface LedgerAddress {
  index: number;
  hdPath: string;
  address: string;
  cosmosPubKey?: string;
  suiPubKey?: string;
  solanaPubKey?: string;
}

export interface EthSignature {
  v: string | number;
  r: string;
  s: string;
}

const BASE_PATHS = {
  ETH: `44'/60'/0'/0`,
  ICX: `44'/4801368'/0'/0'`,
  DOT: `44'/354'/0'/0'/0'`,
  KSM: `44'/434'/0'/0'/0'`,
  WND: `44'/354'/0'/0'/0'`,
  ATOM: `44'/118'/0'/0'`,
  XLM: `44'/148'`,
  SUI: `44'/784''`,
  SOL: `44'/501'`,
  BTC: `84'/0'/0'/0`, // just supporting native segwit for now
};

const DOT_ADDRESS_DEFAULTS = {
  ACCOUNT: 0x80000000,
  CHANGE: 0x80000000,
  INDEX: 0x80000000,
};

const WND_SS58_DECIMAL = 42; // For encoding address
const U2F_ADDRESS_TIMEOUT = 2000;

export interface GetAddressOptions {
  cosmosHrp?: string;
}

export interface SignTransactionOptions {
  cosmosHrp?: string;
  rawBtcInputsHex?: string[];
}

export interface SendCosmosTokensOptions {
  cosmosHrp?: string;
}

export async function getCosmosLedgerSigner(prefix: string, index?: number) {
  const [isSupportedWebHID, isSupportedWebUSB, isSupportedU2F] = await Promise.all([
    TransportWebHID.isSupported(),
    TransportWebUSB.isSupported(),
    TransportU2F.isSupported(),
  ]);
  if (!(isSupportedWebHID || isSupportedWebUSB || isSupportedU2F)) {
    throw new Error(LedgerErrors.NotSupported);
  }

  let transport: Transport | null = null;
  /* if (isSupportedWebUSB) {
    try {
      transport = await getTransportWebUSB();
    } catch (error: any) {
      // ignore, try TransportWebHID
    }
  } */
  if (isNil(transport) && isSupportedWebHID) {
    try {
      transport = await getTransportWebHID();
    } catch (error: any) {
      // ignore, try TransportU2F
    }
  }
  if (isNil(transport) && isSupportedU2F) {
    try {
      transport = await getTransportU2F();
    } catch (error: any) {
      // ignore, will throw next
    }
  }
  if (isNil(transport)) throw new Error(LedgerErrors.NotConnected);
  const hdPaths = index ? [makeCosmoshubPath(index)] : undefined;

  return new CosmosLedgerSigner(transport, { prefix, hdPaths });
}

export async function getLedgerApp(
  appType: AppType
): Promise<AppEth | AppIcx | SubstrateApp | CosmosApp | AppStr | AppSui | AppSol | AppBtc> {
  const [isSupportedWebHID, isSupportedWebUSB, isSupportedU2F] = await Promise.all([
    TransportWebHID.isSupported(),
    TransportWebUSB.isSupported(),
    TransportU2F.isSupported(),
  ]);
  if (!(isSupportedWebHID || isSupportedWebUSB || isSupportedU2F)) {
    throw new Error(LedgerErrors.NotSupported);
  }

  let transport: Transport | null = null;
  /* if (isSupportedWebUSB) {
    try {
      transport = await getTransportWebUSB();
    } catch (error: any) {
      // ignore, try TransportWebHID
    }
  } */
  if (isNil(transport) && isSupportedWebHID) {
    try {
      transport = await getTransportWebHID();
    } catch (error: any) {
      // ignore, try TransportU2F
    }
  }
  if (isNil(transport) && isSupportedU2F) {
    try {
      transport = await getTransportU2F();
    } catch (error: any) {
      // ignore, will throw next
    }
  }
  if (isNil(transport)) throw new Error(LedgerErrors.NotConnected);

  switch (appType) {
    case AppType.ETH:
      return getAppEth(transport);

    case AppType.ICX:
      return getAppIcx(transport);

    case AppType.DOT:
      return getAppDot(transport);

    case AppType.KSM:
      return getAppKsm(transport);

    case AppType.WND:
      return getAppWnd(transport);

    case AppType.ATOM:
      return getAppAtom(transport);

    case AppType.XLM:
      return getAppStr(transport);

    case AppType.SUI:
      return getAppSui(transport);

    case AppType.SOL:
      return getAppSol(transport);

    case AppType.BTC:
      return getAppBtc(transport);
  }
}

async function getTransportWebUSB(): Promise<Transport> {
  const devices = await TransportWebUSB.list();
  if (isEmpty(devices)) {
    try {
      await TransportWebUSB.request();
    } catch (error: any) {
      console.warn('Hana LedgerBridge', 'Failed requesting WebUSB device access.', error);
      throw new Error(LedgerErrors.NotConnected);
    }
  }

  return TransportWebUSB.create();
}

async function getTransportWebHID(): Promise<Transport> {
  const devices = await TransportWebHID.list();
  if (isEmpty(devices)) {
    try {
      await TransportWebHID.request();
    } catch (error: any) {
      console.warn('Hana LedgerBridge', 'Failed requesting WebHID device access.', error);
      throw new Error(LedgerErrors.NotConnected);
    }
  }

  return TransportWebHID.create();
}

async function getTransportU2F(): Promise<Transport> {
  return TransportU2F.create();
}

async function getAppEth(transport: Transport): Promise<AppEth> {
  try {
    const app = new AppEth(transport);
    const response = await Promise.race([
      getLedgerAddress(app, AppType.ETH, 0),
      wait(U2F_ADDRESS_TIMEOUT),
    ]);
    if (isNil(response)) {
      throw new Error(`Timed out getting address from ${AppType.ETH} app.`);
    }
    return app;
  } catch (error: any) {
    console.warn('Hana LedgerBridge', `Failed creating ${AppType.ETH} app.`, error);
    throw new Error(LedgerErrors.NoAddress);
  }
}

async function getAppIcx(transport: Transport): Promise<AppIcx> {
  try {
    const app = new AppIcx(transport);
    const response = await Promise.race([
      getLedgerAddress(app, AppType.ICX, 0),
      wait(U2F_ADDRESS_TIMEOUT),
    ]);
    if (isNil(response)) {
      throw new Error(`Timed out getting address from ${AppType.ICX} app.`);
    }
    return app;
  } catch (error: any) {
    console.warn('Hana LedgerBridge', `Failed creating ${AppType.ICX} app.`, error);
    throw new Error(LedgerErrors.NoAddress);
  }
}

async function getAppDot(transport: Transport): Promise<SubstrateApp> {
  try {
    const app = newPolkadotApp(transport);
    await getLedgerAddress(app, AppType.DOT, 0);
    return app;
  } catch (error: any) {
    console.warn('Hana LedgerBridge', `Failed creating ${AppType.DOT} app.`, error);
    throw new Error(LedgerErrors.NoAddress);
  }
}

async function getAppKsm(transport: Transport): Promise<SubstrateApp> {
  try {
    const app = newKusamaApp(transport);
    await getLedgerAddress(app, AppType.KSM, 0);
    return app;
  } catch (error: any) {
    console.warn('Hana LedgerBridge', `Failed creating ${AppType.KSM} app.`, error);
    throw new Error(LedgerErrors.NoAddress);
  }
}
async function getAppWnd(transport: Transport): Promise<SubstrateApp> {
  try {
    const app = newKusamaApp(transport);
    await getLedgerAddress(app, AppType.WND, 0);
    return app;
  } catch (error: any) {
    console.warn('Hana LedgerBridge', `Failed creating ${AppType.WND} app.`, error);
    throw new Error(LedgerErrors.NoAddress);
  }
}

async function getAppAtom(transport: Transport): Promise<CosmosApp> {
  try {
    const app = new CosmosApp(transport);
    // await getLedgerAddress(app, AppType.ATOM, 0);
    return app;
  } catch (error: any) {
    console.warn('Hana LedgerBridge', `Failed creating ${AppType.ATOM} app.`, error);
    throw new Error(LedgerErrors.NoAddress);
  }
}

async function getAppStr(transport: Transport): Promise<AppStr> {
  try {
    const app = new AppStr(transport);
    await getLedgerAddress(app, AppType.XLM, 0);
    return app;
  } catch (error: any) {
    console.warn('Hana LedgerBridge', `Failed creating ${AppType.XLM} app.`, error);
    throw new Error(LedgerErrors.NoAddress);
  }
}

async function getAppSui(transport: Transport): Promise<AppSui> {
  try {
    const app = new AppSui(transport);
    await getLedgerAddress(app, AppType.SUI, 0);
    return app;
  } catch (error: any) {
    console.warn('Hana LedgerBridge', `Failed creating ${AppType.SUI} app.`, error);
    throw new Error(LedgerErrors.NoAddress);
  }
}

async function getAppSol(transport: Transport): Promise<AppSol> {
  try {
    const app = new AppSol(transport);
    await getLedgerAddress(app, AppType.SOL, 0);
    return app;
  } catch (error: any) {
    console.warn('Hana LedgerBridge', `Failed creating ${AppType.SOL} app.`, error);
    throw new Error(LedgerErrors.NoAddress);
  }
}

async function getAppBtc(transport: Transport): Promise<AppBtc> {
  try {
    const app = new AppBtc({ transport });
    await getLedgerAddress(app, AppType.BTC, 0);
    return app;
  } catch (error: any) {
    console.warn('Hana LedgerBridge', `Failed creating ${AppType.BTC} app.`, error);
    throw new Error(LedgerErrors.NoAddress);
  }
}

function isEIP712Data(message: string) {
  try {
    const data = JSON.parse(message);

    if (typeof data !== 'object' || data === null) {
      return false;
    }

    const hasDomain = data.domain && typeof data.domain === 'object';
    const hasMessage = data.message && typeof data.message === 'object';
    const hasTypes = data.types && typeof data.types === 'object' && 'EIP712Domain' in data.types;

    return hasDomain && hasMessage && hasTypes;
  } catch (error) {
    return false;
  }
}

function eip712RemoveUnusedTypes(primaryType: string, types: any) {
  const usedTypes: any = {};

  function findUsedTypes(currentType: string) {
    if (usedTypes[currentType]) {
      // Already processed this type
      return;
    }

    const fields = types[currentType];
    if (!fields) {
      throw new Error(`Type ${currentType} is not defined`);
    }

    usedTypes[currentType] = fields;

    fields.forEach((field: any) => {
      if (types[field.type]) {
        // This type is a custom type, delve deeper
        findUsedTypes(field.type);
      }
    });
  }

  findUsedTypes(primaryType);
  return usedTypes;
}

export async function signMessage(
  app: AppEth | AppIcx | SubstrateApp | CosmosApp | AppStr | AppSui | AppSol,
  appType: AppType,
  hdPath: string,
  message: string
): Promise<string> {
  if (appType === AppType.ICX) {
    throw new Error('Cannot sign with Ledger ICON wallet');
  } else if (appType === AppType.DOT || appType === AppType.KSM || appType === AppType.WND) {
    throw new Error('Cannot sign with Ledger DOT wallet');
  }

  if (appType === AppType.SUI) {
    const txBuffer = Buffer.from(message, 'base64');
    const { signature: signatureBuffer } = await (app as AppSui).signTransaction(
      hdPath,
      messageWithIntent('PersonalMessage', txBuffer)
    );

    const { publicKey } = await (app as AppSui).getPublicKey(hdPath);
    return toSerializedSignature({
      signature: signatureBuffer,
      signatureScheme: 'ED25519',
      publicKey: new Ed25519PublicKey(publicKey),
    });
  }

  if (appType === AppType.SOL) {
    const messageBuffer = Buffer.from(message, 'base64');

    // 3. Sign the raw message
    const { signature } = await (app as AppSol).signOffchainMessage(hdPath, messageBuffer);

    return Buffer.from(signature).toString('base64');
  }

  if (appType === AppType.XLM) {
    const messageBuffer = Buffer.from(message);
    const signature = await (app as AppStr).signHash(hdPath, messageBuffer);
    return signature.signature.toString('hex');
  }

  if (/^0x/.test(message)) message = message.slice(2);

  const isEip712 = isEIP712Data(message);

  let result: {
    v: number;
    s: string;
    r: string;
  };

  if (isEip712) {
    const data = JSON.parse(message);

    const domainSeparator = ethers.utils._TypedDataEncoder.hashDomain(data.domain);

    // Remove EIP712Domain from types
    delete data.types.EIP712Domain;

    // Remove unused types
    data.types = eip712RemoveUnusedTypes(data.primaryType, data.types);

    const hashStructMessage = ethers.utils._TypedDataEncoder.hashStruct(
      data.primaryType,
      data.types,
      data.message
    );
    console.debug('hashStructMessage: ', hashStructMessage);

    result = await (app as AppEth).signEIP712HashedMessage(
      hdPath,
      domainSeparator,
      hashStructMessage
    );
  } else {
    result = await (app as AppEth).signPersonalMessage(hdPath, message);
  }

  const v = result['v'] - 27;
  let vString = v.toString(16);
  if (vString.length < 2) {
    vString = '0' + v;
  }

  const signature = `0x${result.r}${result.s}${vString}`;

  return signature;
}

export async function signTransaction(
  app: AppEth | AppIcx | SubstrateApp | CosmosLedgerSigner | AppStr | AppSui | AppSol | AppBtc,
  appType: AppType,
  fromAddress: LedgerAddress,
  serializedTx: string,
  options?: SignTransactionOptions
): Promise<EthSignature | string> {
  const { hdPath, index } = fromAddress;

  switch (appType) {
    case AppType.ETH:
      const ethResolution = await ledgerService.resolveTransaction(serializedTx, {}, {});
      const ethResponse: EthSignature = await (app as AppEth).signTransaction(
        hdPath,
        serializedTx,
        ethResolution
      );
      console.log('LedgerBridge.signTransaction', 'got signature?', ethResponse);
      return {
        v: `0x${ethResponse.v}`,
        r: `0x${ethResponse.r}`,
        s: `0x${ethResponse.s}`,
      };

    case AppType.ICX:
      const icxResponse = await (app as AppIcx).signTransaction(hdPath, serializedTx);
      return icxResponse.signedRawTxBase64;

    case AppType.DOT:
    case AppType.KSM:
    case AppType.WND:
      const dotResponse = await (app as SubstrateApp).sign(
        DOT_ADDRESS_DEFAULTS.ACCOUNT,
        DOT_ADDRESS_DEFAULTS.CHANGE,
        DOT_ADDRESS_DEFAULTS.INDEX + index,
        Buffer.from(serializedTx, 'hex')
      );
      if (isNil(dotResponse.signature)) {
        throw new Error(`${dotResponse.error_message} (code: ${dotResponse.return_code})`);
      }
      return dotResponse.signature.toString('hex');

    case AppType.ATOM:
      let stdSignDoc = JSON.parse(serializedTx) as StdSignDoc;

      // Convert msgs.value.data to Uint8Array if needed
      const { msgs } = stdSignDoc;

      let updatedMsgs = msgs;

      updatedMsgs.forEach((msg: any) => {
        const data = msg.value.data;

        if (data) {
          const isArray = Array.isArray(data);

          if (isArray) {
            const uint8Array = new Uint8Array(data);
            msg.value.data = uint8Array;
          }
        }
      });

      stdSignDoc = {
        ...stdSignDoc,
        msgs: updatedMsgs,
      };

      const response = await (app as CosmosLedgerSigner).signAmino(fromAddress.address, stdSignDoc);

      return JSON.stringify(response);

    case AppType.XLM:
      const transaction = Buffer.from(serializedTx, 'base64');
      const xlmResponse = await (app as AppStr).signTransaction(hdPath, transaction);
      const signatureBuffer = xlmResponse.signature;
      const signature = signatureBuffer.toString('base64');
      return signature;

    case AppType.SUI: {
      const txBuffer = Buffer.from(serializedTx, 'base64');
      const { signature: signatureBuffer } = await (app as AppSui).signTransaction(
        hdPath,
        messageWithIntent('TransactionData', txBuffer)
      );

      const { publicKey } = await (app as AppSui).getPublicKey(hdPath);
      return toSerializedSignature({
        signature: signatureBuffer,
        signatureScheme: 'ED25519',
        publicKey: new Ed25519PublicKey(publicKey),
      });
    }

    case AppType.SOL:
      const txBuffer = Buffer.from(serializedTx, 'base64');
      const { signature: solSignature } = await (app as AppSol).signTransaction(hdPath, txBuffer);
      return Buffer.from(solSignature).toString('base64');

    case AppType.BTC:
      const psbtTxn = Psbt.fromBase64(serializedTx);
      const unsignedTxBuffer = psbtTxn.data.globalMap.unsignedTx.toBuffer();

      const inLedgerTx = (app as AppBtc).splitTransaction(unsignedTxBuffer.toString('hex'), true);

      const inputs = psbtTxn.txInputs.map((utxo, index) => {
        if (!options?.rawBtcInputsHex) {
          throw new Error('BTC input hex is required!');
        }
        const rawHex = options.rawBtcInputsHex[index];
        const inputTxn = (app as AppBtc).splitTransaction(rawHex, true);
        return [inputTxn, utxo.index, undefined, 0] as [
          typeof inputTxn,
          number,
          string | null | undefined,
          number | undefined
        ];
      });

      const outputScriptHex = (app as AppBtc)
        .serializeTransactionOutputs(inLedgerTx)
        .toString('hex');

      return (app as AppBtc).createPaymentTransaction({
        inputs,
        associatedKeysets: Array(inputs.length).fill(hdPath),
        outputScriptHex,
        additionals: ['bech32'],
      });

    // case AppType.ATOM:
    //   // TODO: Use signAmino instead?
    //   // Check if these are actually used or needed

    //   const path = [44, 118, 0, 0, index];

    //   // const responsePk = await (app as CosmosApp).publicKey(path);
    //   console.debug('signTransaction options: ', options);

    //   const responsePk = await (app as CosmosApp).getAddressAndPubKey(
    //     path,
    //     options?.cosmosHrp || 'cosmos'
    //   );

    //   console.debug('responsePk: ', responsePk);

    //   if (responsePk.return_code !== 0x9000)
    //     throw new Error(
    //       responsePk.error_message
    //         ? responsePk.error_message
    //         : `Ledger device returned error code: ${responsePk.return_code}`
    //     );

    //   const message = String.raw`${serializedTx}`;

    //   console.debug('message: ', message);

    //   const { return_code, error_message, signature } = await (app as CosmosApp).sign(
    //     path,
    //     Buffer.from(message)
    //   );

    //   console.debug('LedgerBridge.signTransaction', {
    //     return_code,
    //     error_message,
    //     signature,
    //   });

    //   if (return_code !== 0x9000)
    //     // TODO: This might not be correct
    //     throw new Error(
    //       error_message ? error_message : `Ledger device returned error code: ${return_code}`
    //     );

    //   const result = JSON.stringify({
    //     signature: signature.toString('base64'),
    //     pub_key: {
    //       type: '/cosmos.crypto.secp256k1.PubKey',
    //       value: responsePk.compressed_pk.toString('base64'),
    //     },
    //   });

    //   console.debug('result: ', result);

    //   return result;
  }
}

export async function getLedgerAddress(
  app: AppEth | AppIcx | SubstrateApp | CosmosLedgerSigner | AppStr | AppSui | AppSol | AppBtc,
  appType: AppType,
  index: number,
  options?: GetAddressOptions
): Promise<LedgerAddress> {
  const hdPath = getAddressPath(appType, index);
  switch (appType) {
    case AppType.ETH:
      let { address: ethAddress } = await (app as AppEth).getAddress(hdPath, false, true);
      return { index, hdPath, address: ethAddress };

    case AppType.ICX:
      let { address: icxAddress } = await (app as AppIcx).getAddress(hdPath, false, true);
      return { index, hdPath, address: icxAddress.toString() };

    case AppType.DOT:
    case AppType.KSM:
    case AppType.WND:
      let { address: dotAddress } = await (app as SubstrateApp).getAddress(
        DOT_ADDRESS_DEFAULTS.ACCOUNT,
        DOT_ADDRESS_DEFAULTS.CHANGE,
        DOT_ADDRESS_DEFAULTS.INDEX + index
      );
      if (appType === AppType.WND) {
        dotAddress = encodeAddress(dotAddress, WND_SS58_DECIMAL);
      }

      return { index, hdPath, address: dotAddress };

    case AppType.ATOM:
      /*
      const path = [44, 118, 0, 0, index];

      let { bech32_address, compressed_pk, return_code, error_message } = await (
        app as CosmosApp
      ).getAddressAndPubKey(path, options?.cosmosHrp || 'cosmos');

      console.debug('LedgerBridge.getLedgerAddress', {
        bech32_address,
        compressed_pk,
        return_code,
        error_message,
      });

      return { index, hdPath, address: bech32_address };*/

      const accounts = await (app as CosmosLedgerSigner).getAccounts();
      const account = accounts[0];

      const { address, pubkey, algo } = account;

      console.debug('account: ', account);

      const addressSerialized = JSON.stringify(Array.from(rawSecp256k1PubkeyToRawAddress(pubkey)));
      const pubkeySerialized = JSON.stringify(Array.from(pubkey));

      const key = {
        address: addressSerialized,
        algo, // TODO
        isKeystone: false,
        isNanoLedger: true,
        name: '',
        bech32Address: address,
        pubKey: pubkeySerialized,
      };

      console.debug('key: ', key);

      return { index, hdPath, address, cosmosPubKey: JSON.stringify(key) };

    case AppType.XLM:
      const { publicKey: xlmAddress } = await (app as AppStr).getPublicKey(hdPath);
      return { index, hdPath, address: xlmAddress };

    case AppType.SUI: {
      const pubKey = await (app as AppSui).getPublicKey(hdPath);
      return {
        index,
        hdPath,
        address: `0x${Buffer.from(pubKey.address).toString('hex')}`,
        suiPubKey: `0x${Buffer.from(pubKey.publicKey).toString('hex')}`,
      };
    }

    case AppType.SOL:
      const { address: publicKey } = await (app as AppSol).getAddress(hdPath);
      return {
        index,
        hdPath,
        address: new PublicKey(publicKey).toBase58(),
        solanaPubKey: new PublicKey(publicKey).toBase58(),
      };

    case AppType.BTC: {
      const { bitcoinAddress, publicKey } = await (app as AppBtc).getWalletPublicKey(hdPath, {
        format: 'bech32',
      });
      return {
        index,
        hdPath,
        address: bitcoinAddress,
      };
    }
  }
}

export async function estimateCosmosTransaction(
  app: CosmosLedgerSigner,
  params: EstimateCosmosTxParams
) {
  const { rpcUrl, fromAddress, messages, gasPrice: _gasPrice, gasPriceMultiplier } = params;

  const client = await SigningStargateClient.connectWithSigner(rpcUrl, app);

  const gasUsed = await client.simulate(fromAddress.address, messages, 'Optional memo');
  const gasUsedAdjusted = gasUsed * (gasPriceMultiplier || 1.5);

  const price = parseInt(Math.ceil(gasUsedAdjusted).toString());

  const gasPrice = _gasPrice || 0.025;

  return {
    amount: gasPrice,
    price,
  };
}

export async function sendCosmosTokens(
  app: CosmosLedgerSigner,
  params: SendCosmosTokensParams
): Promise<string | { amount: number; price: number }> {
  // console.debug('Ledger bridge sendCosmosTokens: ', {
  //   app,
  //   params,
  // });
  const {
    rpcUrl,
    prefix,
    index,
    fromAddress,
    toAddress,
    txAmount,
    denom,
    contract,
    feeDenom,
    memo,
    estimate,
    gasPrice: _gasPrice,
    gasPriceMultiplier,
  } = params;

  if (!isNil(contract)) {
    const client = await SigningCosmWasmClient.connectWithSigner(rpcUrl, app);

    // Construct the transfer message for the CW20 token contract
    const transferMsg = {
      transfer: {
        recipient: toAddress,
        amount: txAmount,
      },
    };

    // The execute message must be wrapped properly for simulation
    const executeMsg = {
      typeUrl: '/cosmwasm.wasm.v1.MsgExecuteContract',
      value: MsgExecuteContract.fromPartial({
        sender: fromAddress.address,
        contract,
        msg: Buffer.from(JSON.stringify(transferMsg)),
        funds: [],
      }),
    };

    const gasUsed = await client.simulate(fromAddress.address, [executeMsg], memo);
    const gasUsedAdjusted = gasUsed * (gasPriceMultiplier || 1.5);

    const price = parseInt(Math.ceil(gasUsedAdjusted).toString());

    const gasPrice = _gasPrice || 0.025;

    if (estimate === true) {
      return {
        amount: gasPrice,
        price,
      };
    } else {
      let feeAmount = Math.ceil(gasPrice * price);

      const fee = {
        amount: coins(feeAmount.toString(), feeDenom),
        gas: Math.ceil(price * 1.3).toString(),
      };

      const hash = await client.signAndBroadcastSync(fromAddress.address, [executeMsg], fee, memo);
      return hash;
    }
  }

  if (!isNil(denom)) {
    const client = await SigningStargateClient.connectWithSigner(rpcUrl, app);

    const amount = coins(txAmount, denom);

    const message: EncodeObject = {
      typeUrl: '/cosmos.bank.v1beta1.MsgSend',
      value: {
        fromAddress: fromAddress.address,
        toAddress,
        amount,
      },
    };
    const gasUsed = await client.simulate(fromAddress.address, [message], memo);
    const gasUsedAdjusted = gasUsed * (gasPriceMultiplier || 1.5);

    const price = parseInt(Math.ceil(gasUsedAdjusted).toString());

    const gasPrice = _gasPrice || 0.025;

    if (estimate === true) {
      return {
        amount: gasPrice,
        price,
      };
    } else {
      let feeAmount = Math.ceil(gasPrice * price);

      const fee = {
        amount: coins(feeAmount.toString(), feeDenom),
        gas: Math.ceil(price * 1.3).toString(),
      };

      const hash = await client.signAndBroadcastSync(fromAddress.address, [message], fee, memo);
      return hash;
    }
  }

  throw new Error('Invalid token');
}

export async function getCosmosPublicKey(app: CosmosLedgerSigner) {
  try {
    const accounts = await app.getAccounts();
    const account = accounts[0];

    const { algo, address, pubkey } = account;

    const addressSerialized = JSON.stringify(Array.from(rawSecp256k1PubkeyToRawAddress(pubkey)));
    const pubkeySerialized = JSON.stringify(Array.from(pubkey));

    const key = {
      address: addressSerialized,
      algo,
      isKeystone: false,
      isNanoLedger: true,
      name: '',
      bech32Address: address,
      pubKey: pubkeySerialized,
    };

    console.debug('getCosmosPublicKey key: ', key);

    return key;
  } catch (error) {
    console.debug('getCosmosPublicKey error: ', error);
  }
}

function getAddressPath(appType: AppType, index: number): string {
  const basePath = getBasePath(appType);

  let addressPath = `${basePath}/${index}`;
  if (appType === AppType.ICX || appType === AppType.XLM) {
    addressPath += `'`;
  } else if (appType === AppType.SUI) {
    addressPath += `'/0'/0'`;
  } else if (appType === AppType.SOL) {
    addressPath += `'/0'/0'`;
  }
  return addressPath;
}

function getBasePath(appType: AppType): string {
  switch (appType) {
    case AppType.ETH:
      return BASE_PATHS.ETH;

    case AppType.ICX:
      return BASE_PATHS.ICX;

    case AppType.DOT:
    case AppType.WND:
      return BASE_PATHS.DOT;

    case AppType.KSM:
      return BASE_PATHS.KSM;

    case AppType.ATOM:
      return BASE_PATHS.ATOM;

    case AppType.XLM:
      return BASE_PATHS.XLM;

    case AppType.SUI:
      return BASE_PATHS.SUI;

    case AppType.SOL:
      return BASE_PATHS.SOL;

    case AppType.BTC:
      return BASE_PATHS.BTC;
  }
}
